/**
 *  Product List
 */

export default [
    {
        "id":1,
        "name":"Mobile Ui",
        "images":"02.jpg",
        "category":["App"],
    },  
    {
        "id":2,
        "name":"Creative Design",
        "images":"01.jpg",
        "category":["Design"],
    },  
    {
        "id":3,
        "name":"Design Mockup",
        "images":"03.jpg",
        "category":["Branding"],
    },  
    {
        "id":4,
        "name":"Mobile Screen",
        "images":"04.jpg",
        "category":["App"],
    },  
    {
        "id":5,
        "name":"Creative Cake",
        "images":"05.jpg",
        "category":["Branding"],
    },  
    {
        "id":6,
        "name":"Social Media",
        "images":"06.jpg",
        "category":["Design"],
    },  
    {
        "id":7,
        "name":"Development",
        "images":"07.jpg",
        "category":["Design"],
    },  

]